<template>
  <div class="mod-home">
    <component v-bind:is='currentHome'></component>
  </div>
</template>

<script>
import exampleHome from '../example_modules/home.vue'
import example1Home from '../example_module1/home.vue'
import stationHome from '../health_station/home.vue'
import my_healthHome from '../personal_module/home.vue'
import communityHome from '../community_station/home.vue'
import doctorHome from '../doctor_station/home.vue'
import WomenBabyhome from '../women_baby/home.vue'
import OlderStation from '../older_station/home.vue'
import HomeOlder from '../home_older/home.vue'
export default {
  name: 'home',
  data () {
    return {
    }
  },
  components: {
    exampleHome,
    example1Home,
    stationHome,
    my_healthHome,
    communityHome,
    doctorHome,
    WomenBabyhome,
    OlderStation,
    HomeOlder
  },
  computed: {
    currentHome: function () {
      if (this.$store.state.module.moduleName === 'example_module') {
        return 'exampleHome'
      }
      else if (this.$store.state.module.moduleName === 'example_module1') {
        return 'example1Home'
      }
      else if (this.$store.state.module.moduleName === 'health_station') {
        return 'stationHome'
      }
      else if (this.$store.state.module.moduleName === 'personal_module') {
        return 'my_healthHome'
      }
      else if (this.$store.state.module.moduleName === 'community_station') {
        return 'communityHome'
      }
      else if (this.$store.state.module.moduleName === 'doctor_station') {
        return 'doctorHome'
      }
      else if (this.$store.state.module.moduleName === 'women_baby') {
        return 'WomenBabyhome'
      }else if (this.$store.state.module.moduleName === 'older_station') {
        return 'OlderStation'
      }
      else if (this.$store.state.module.moduleName === 'home_older') {
        return 'HomeOlder'
      }
      else {
        return 'exampleHome'
      }
    },
  },
  created () {

  },
  mounted(){
  },

  methods: {
  },


}
</script>

<style lang="scss" scoped>
.mod-home {
  line-height: 1;
}
</style>
