<template>
  <div class="mod-home">
    <div class="home-title">
      <div class="text-title" style="">社区健康平台</div>
      <div class="text-home">
        <span class="text-home-sub1">
          心狗理念
        </span>
        <span class="text-home-sub2">
          健康中国——为百姓健康保驾护航
        </span>
      </div>

    </div>

    <br/>
    <p style="font-size: 25px; font-weight: bold;">{{Uname}}卫健委:</p>
    <p style="font-size: 25px; text-indent: 50px">
      您好,欢迎来到心狗健康管理系统家庭医生签约-社区健康平台！
      您上一次登录时间是{{lastlogin}}。
    </p>
    <br>
    <br>
    <p style="font-size: 25px; font-weight: bold;">最新数据</p>

    <div class="data-item1">
      <div class="item1"
           v-for="item in titleItem1"
           :key="item.title">
        <!-- <div class="display: inline-block;"> -->
        <div class="title1">{{ item.title }}</div>
        <div  class="number1">
          {{item.number}}
        </div>
        <div class="auxiliary1">较上次登录 + 0</div>
        <!-- </div> -->
        <!-- <div class="span1"></div> -->
      </div>

    </div>

    <div class="data-item2">
      <div class="item2"
           v-for="item in titleItem2"
           :key="item.title">
        <div class="title2">{{ item.title }}</div>
        <div  class="number2">
          {{item.number}}
        </div>
        <div class="auxiliary2">较上次登录 + 0</div>
      </div>
    </div>

    <div class="data-item">
      <div class="item"
           v-for="item in titleItem"
           :key="item.title">
        <div class="title">{{ item.title }}</div>
        <div  class="number">
          {{item.number}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'home',
  data () {
    return {
      titleItem1: [
        {
          title: "户籍人口",
          number: 24000,

        },
        {
          title: "户籍常住人口",
          number: 20000,
        },
        {
          title: "常住人口",
          number: 23000,
        },
      ],
      titleItem2: [
        {
          title: "签约人口",
          number: 0,
        },
        {
          title: "签约医生",
          number: 0,

        },
        {
          title: "健康小屋",
          number: 0,
        },
      ],
      titleItem: [
        {
          title: "高血压",
          number: 0,
        },
        {
          title: "糖尿病",
          number: 0,
        },
        {
          title: "冠心病",
          number: 0,

        },
        {
          title: "慢性阻塞性肺疾病",
          number: 0,
        },
        {
          title: "恶性肿瘤",
          number: 0,
        },
        {
          title: "严重精神障碍",
          number: 0,
        },
      ],
      Uname: '测试',
      lastlogin: '16点20分',
      // Uname: this.$store.state.user.adminName,
      // lastlogin: this.$store.state.user.lastLogin,
      // basicinfo :{
      //   signedDocter: 10,
      //   signedNumber: 0,
      //   signedEnterprise:0
      // },

      permanent_number: 1200,
      registered_permanent_population:750,
      registered_population:900,

      conclusion:{region:'本地区',totalNum:100000,inhabitant:2200, registeredResidence:2020,signedNum:0,
        hypertensionNum:0,diabetesNum:0,coronaryHeartDiseaseNum:0,COPDNum:0,malignancyNum:0,apoplexyNum:0,
        SeverementaldisorderNum:0,tuberculosisNum:0,hepatitisNum:0,epidemicNum:0,
        occupationalDiseasesNum:0,others:0}

    }
  },
  created () {

  },
  mounted(){
  },

  methods: {
  },


}
</script>

<style lang="scss" scoped>
.mod-home {
  line-height: 1;
}
.home-title {
  background-image: linear-gradient(45deg, #830707, #b30808);
  padding-top:15px;
  padding-bottom:15px;
  .text-title{
    font-size: 40px;
    text-align: center;
    color: #ffffff;
    padding-bottom: 20px;
    font-weight: bold;
  }
  .text-home {
    text-align: center;
    font-size: 28px;
    color: rgb(255, 255, 255);
    .text-home-sub1{
      color: #830707;
      background-color: #ffffff;
      font-weight: bold;
      border-radius: 4px;
      padding-left: 5px;
    }
    .text-home-sub2{
      padding-left: 15px;
    }
  }
}
.data-item{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: start;

  :hover {
    background-color: #8dc0bb;
    text-decoration: none;
  }
  .item {
    padding: 5px;
    // padding-left: 10px;
    margin: 10px;
    // margin-left: 10px;
    width: 20%;
    height: 50px;
    line-height: 40px;
    // flex-wrap: nowrap;
    text-align: center;
    font-weight: bold;
    .title{
      font-size: 24px;
      height: 100%;
      color: rgb(255, 255, 255);
      background-color: #464545;
      width: 60%;
      display: inline-block;
      border-radius: 4px;

      white-space: nowrap;
      text-overflow: ellipsis;

    }
    // :hover {
    //     color: #0077ff;
    //     text-decoration: none;
    //   }
    .number{
      height: 100%;
      // width: 100%;
      font-size: 28px;
      color: #830707;
      background-color: #e0e0e0;
      width: 40%;
      display: inline-block;
      border-radius: 4px;


    }

  }
}

.data-item1{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  padding-bottom: 30px;
  text-align: center;
  // :hover {
  //         background-color: #8dc0bb;
  //         text-decoration: none;
  //       }
  .item1 {
    border-radius: 4px;
    padding-top: 10px;
    padding-left: 10px;
    margin-top: 10px;
    margin-left: 10px;
    width: 15%;
    height: 80px;
    flex-wrap: nowrap;


    // background-color: #17B3A3;

    .title1{
      font-size: 24px;
      color: rgb(0, 0, 0);
      padding-bottom: 10px;

    }
    :hover {
      color: #0077ff;
      text-decoration: none;
    }
    .number1{
      // height: 100%;
      width: 100%;
      font-size: 32px;
      color: #17B3A3;
      font-weight: bold;
    }
    .auxiliary1{
      font-size: 10px;
      padding-top: 10px;
      color: #17B3A3;
    }

  }
  // .span1 {
  //   padding:12px 6px 50px 6px;
  //   margin-left: 6px;
  //   border-left: 1px solid;
  //   font-size: 0;
  // }
}



.data-item2{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  padding-bottom: 30px;
  text-align: center;
  // :hover {
  //         background-color: #8dc0bb;
  //         text-decoration: none;
  //       }
  .item2 {
    border-radius: 4px;
    padding-top: 10px;
    padding-left: 10px;
    margin-top: 10px;
    margin-left: 10px;
    width: 15%;
    height: 80px;
    flex-wrap: nowrap;
    // background-color: #17B3A3;

    .title2{
      font-size: 24px;
      color: rgb(0, 0, 0);
      padding-bottom: 10px;

    }
    :hover {
      color: #0077ff;
      text-decoration: none;
    }
    .number2{
      // height: 80%;
      width: 100%;
      font-size: 32px;
      color: rgb(0, 162, 255);
      font-weight: bold;
    }
    .auxiliary2{
      font-size: 10px;
      padding-top: 10px;
      color: rgb(0, 162, 255);
    }

  }
}

</style>
